<template>
  <div @click.stop="close">
    <button
      type="button"
      class="btn btn-primary"
      id="button-open-dialog"
      data-bs-toggle="modal"
      data-bs-target="#kt_modal_1"
      hidden
    ></button>
    <div class="modal fade" tabindex="-1" id="kt_modal_1">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header d-flex justify-content-between">
            <h2 class="modal-title">เพิ่มรายการตัดสินค้าทั้งหมด</h2>
            <button
              data-bs-dismiss="modal"
              @click="close"
              type="button"
              class="btn btn-sm"
            >
              <i
                class="bi bi-x m-0 p-0"
                id="close-btn"
                style="font-size: 25px"
              ></i>
            </button>
          </div>

          <div class="modal-body">
            <div class="row d-flex justify-content-center mb-4">
              <label class="col-sm-9 text-start">เลขที่เอกสาร</label>
              <div class="col-sm-9">
                <input
                  disabled
                  type="text"
                  class="form-control"
                  id="inputGroupFile01"
                />
              </div>
            </div>
            <div class="row d-flex justify-content-center text-start mb-4">
              <label for="input1  form-label" class="col-sm-9"
                >ค้นหาสินค้า</label
              >
              <div class="col-sm-4">
                <input
                  type="text"
                  class="form-control"
                  id="input1"
                  :style="
                    isSubmit
                      ? 'border-color: #ced4da;padding-right: 0.75rem;background: none;'
                      : ''
                  "
                />
              </div>

              <div class="col-sm-3 mb-3">
                <div
                  class="form-check form-check-inline form-check-custom form-check-solid mt-3"
                >
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    value="input1"
                    id="flexRadioChecked"
                  />
                  <label
                    class="form-check-label me-4"
                    for="flexRadioChecked"
                    :style="isSubmit ? 'color: black' : ''"
                    >Item no.</label
                  >
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    value="input2"
                    id="flexRadioChecked2"
                    checked="checked"
                  />
                  <label
                    class="form-check-label"
                    for="flexRadioChecked"
                    :style="isSubmit ? 'color: black' : ''"
                    >Barcode</label
                  >
                </div>
              </div>

              <div class="col-md-2">
                <button
                  type="button"
                  class="btn btn-sm btn-light-primary"
                  style="font-size: 14px; font-weight: bold; width: 100%"
                  width="100%"
                  @click="cancel"
                >
                  ค้นหา
                </button>
              </div>
            </div>

            <div class="row d-flex justify-content-center text-start mb-4">
              <label class="col-sm-9">Item no</label>
              <div class="col-sm-9">
                <input
                  disabled
                  type="text"
                  class="form-control"
                  id="inputGroupFile01"
                />
              </div>
            </div>
            <div class="row d-flex justify-content-center text-start mb-4">
              <label class="col-sm-9">Item detail</label>
              <div class="col-sm-9">
                <input
                  disabled
                  type="text"
                  class="form-control"
                  id="inputGroupFile01"
                />
              </div>
            </div>
            <div class="row d-flex justify-content-center text-start mb-4">
              <label class="col-sm-9">Barcode</label>
              <div class="col-sm-9">
                <input
                  disabled
                  type="text"
                  class="form-control"
                  id="inputGroupFile01"
                />
              </div>
            </div>

            <div class="row d-flex justify-content-center text-start">
              <label class="col-sm-9">จำนวนหน่วย</label>
              <div class="col-sm-9">
                <input type="text" class="form-control" id="inputGroupFile01" />
              </div>
            </div>
          </div>

          <div class="modal-footer d-flex justify-content-start">
            <hr class="pt-0 mt-0" style="color: LightGrey" />

            <footer>
              <div class="card-toolbar">
                <button
                  @click="submit"
                  type="button"
                  class="btn btn-primary me-3"
                  data-bs-dismiss="modal"
                >
                  บันทึก
                </button>
                <button
                  @click="close"
                  type="button"
                  class="btn btn-light"
                  data-bs-dismiss="modal"
                >
                  ยกเลิก
                </button>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dialogAddItem: Boolean,
  },
  data: () => ({
    isSubmit: false,
  }),
  watch: {
    dialogAddItem(val) {
      if (val) {
        document.getElementById("button-open-dialog").click();
      }
    },
  },
  methods: {
    submit() {
      this.close();
    },
    close() {
      this.$emit("closeDialogAddItem");
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .display-phone {
    text-align: left !important;
  }
}
#close-btn:hover {
  background-color: LightGrey;
  border-radius: 25px;
  color: white;
}
</style>
